<template>
  <footer class="footer-container">
    <slot />
    <div class="footer-top">
      <div class="footer-top-base">
        <NuxtLink :to="localePath('/')" class="fire-logo" :aria-label="`${rootStore.brandSettings?.brand_name} Home`" />
        <a v-if="phone" :href="`tel:${phone}`">Phone: {{ phone }}</a>
        <a v-if="textPhone" :href="`sms:${textPhone}`">Text: {{ textPhone }}</a>
        <div v-if="days_of_week && opening_time && closing_time" style="margin-bottom: 24px">
          Hours: {{ days_of_week }} {{ opening_time }}-{{ closing_time }}
        </div>
        <address v-if="street_address && city && postal_code && stateProvince">
          <a :href="`http://maps.google.com/?q=${fullAddress}`">
            {{ street_address }}<br />{{ city }}, {{ stateProvince }} {{ postal_code }}<br />
          </a>
        </address>
        <div>Office visits by appointment only</div>
      </div>
      <div v-if="mainNav" class="footer-top-lists">
        <div v-for="(group, index) in mainNav.link" :key="index + 'section'" class="list-container">
          <button
            :class="['section-header closed']"
            aria-expanded="false"
            aria-label="Show Links"
            @click="toggleOpen($event)"
          >
            {{ group.title }}
          </button>
          <div class="section-list-container">
            <a
              v-for="(item, idx1) in group.link"
              :key="index + 'group' + idx1 + 'item'"
              :href="getExternalLink(item) || getInternalLink(item)"
              class="section-list-item"
            >
              {{ item.title || getInternalTitle(item) }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="content-wrapper">
        <div class="footer-bottom-base">
          <component
            :is="item.href ? 'a' : 'span'"
            v-for="(item, idx) in state.legalContent"
            :key="'footer-bottom' + idx"
            :href="item?.href ? localePath(item.href) : undefined"
            v-bind="item"
            class="legal-text"
          >
            {{ item.title }}
          </component>
        </div>
        <a
          v-if="vendorImage && vendorLink"
          :href="vendorLink.href"
          :aria-label="`${vendorLink.title} - Opens in a new tab`"
          target="_blank"
        >
          <CloudinaryImage
            :public-id="vendorImage"
            :options="{ width: 190, height: 80, quality: 'auto:eco' }"
            :alt="vendorLink.title"
            :image-classes-override="['vendor-image']"
          />
        </a>
      </div>
    </div>
  </footer>
</template>
<script setup lang="ts">
import { getAssetPublicId, extractComponent } from '../util/contentstack/csHelpers';
import { getFirstIndex } from '../util/arrays';
import { useRootStore } from '~/store/index';
import { useNavStore } from '~/store/nav';
const localePath = useLocalePath();
const config = useRuntimeConfig();
const navStore = useNavStore();
const rootStore = useRootStore();

const state = reactive({
  legalContent: [
    {
      title: 'Privacy Policy',
      href: '/privacy-policy',
    },
    {
      title: 'Consent Preferences',
      onClick: openTermlyModal,
      id: 'termly-consent-preferences',
      role: 'button',
      'aria-label': 'Open Consent Preferences',
    },
    {
      title: 'Terms and Conditions',
      href: '/terms-conditions',
    },
    {
      title: 'RSS',
      href: '/rss-syndication',
    },
    {
      title: 'Patent #US D701721S',
      href: '',
    },
    {
      title: `© ${config?.public?.brand}, ${new Date().getFullYear()}`,
      href: '',
    },
  ],
});

/* eslint-disable camelcase */
const footerComputed = computed<any>(() => getFirstIndex(navStore.footer?.items));
const brandSettings = computed<any>(() => extractComponent(footerComputed.value?.brand_settingsConnection)); // TODO - type contentstack modules
const contactInfo = computed<any>(() => brandSettings.value?.contact); // TODO - type contentstack modules
const street_address = computed<any>(() => contactInfo.value?.address?.street_address || '');
const city = computed<any>(() => contactInfo.value?.address?.city || '');
const stateProvince = computed<any>(() => contactInfo.value?.address?.state || '');
const postal_code = computed<any>(() => contactInfo.value?.address?.postal_code || '');
const fullAddress = computed<string>(
  () => `${street_address.value}, ${city.value}, ${stateProvince.value} ${postal_code.value}`
);
const days_of_week = computed<string>(() => contactInfo.value?.hours_of_operation?.days_of_week || '');
const opening_time = computed<string>(() => contactInfo.value?.hours_of_operation?.opening_time || '');
const closing_time = computed<string>(() => contactInfo.value?.hours_of_operation?.closing_time || '');
const phone = computed<string>(() => contactInfo.value?.phone);
const textPhone = computed<string>(() => contactInfo.value?.text_number);
const mainNav = computed<any>(() => extractComponent(footerComputed.value?.main_navConnection));
// const bottomNav = computed<any>(() => extractComponent(footerComputed.value?.bottom_navConnection));
// const logo = computed<string | undefined>(() => getAssetPublicId(footerComputed.value?.logo));
const vendor = computed<any>(() => getFirstIndex(footerComputed.value?.vendor_logo_links));
const vendorImage = computed<string | undefined>(() => getAssetPublicId(vendor.value?.image));
const vendorLink = computed<any>(() => vendor.value?.link);

function toggleOpen(e: MouseEvent) {
  const el = e?.target as HTMLElement;
  const classes = el?.classList;
  const isExpanded = el?.ariaExpanded;
  if (isExpanded === 'true') {
    el.ariaExpanded = 'false';
    el.ariaLabel = 'Show Links';
  } else {
    el.ariaExpanded = 'true';
    el.ariaLabel = 'Hide Links';
  }
  if (classes) {
    classes.toggle('open');
    classes.toggle('closed');
  }
}
function getExternalLink(item: any): string | undefined {
  return item?.external_link?.href || undefined;
}
function getInternalLink(item: any): string | undefined {
  const internalObj = extractComponent(item.internal_linkConnection);
  return internalObj?.url ? localePath(internalObj?.url) : undefined;
}
function getInternalTitle(item: any): string | undefined {
  const internalObj = extractComponent(item.internal_linkConnection);
  return internalObj?.display_title || internalObj?.title;
}
function openTermlyModal() {
  (window as any).displayPreferenceModal();
  return false;
}
</script>
